.it-footer-main {
  background-color: $tertiary;

  .it-brand-wrapper {
    a {
      color: $tertiary-text;

      svg.icon {
        fill: $tertiary-text;
      }

      .it-brand-text {
        h2,
        h3 {
          color: $tertiary-text;
        }

        h2 {
          font-size: 2em;
        }

        h3 {
          font-size: 1em;
          font-weight: 600;
        }
      }
    }
  }
}

h4 {
  border-bottom: 1px solid rgba($white, 0.5);
  padding-bottom: 0.4em;
  margin-bottom: 0.4em;

  &.no-border {
    padding: 0;
    border: none;
    line-height: 1rem;
    margin-bottom: 1.5rem;

    a {
      text-decoration: underline;
    }
  }
}

.it-footer-small-prints {
  background-color: $tertiary;

  .container {
    border-top: 1px solid $white;
  }
}
