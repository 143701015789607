//mobile
.it-header-center-wrapper {
  .it-header-center-content-wrapper {
    //brand-wrapper
    .it-brand-wrapper {
      a {
        .icon {
          width: auto;
          height: 70px;
        }
      }
      .it-brand-text {
        display: none;
      }
    }
    //-search
    .it-search-wrapper {
      a {
        &.rounded-icon {
          svg {
            //fill: $header-center-text-color;
          }
        }
      }
    }

    //right-zone
    .it-right-zone {
      .it-socials {
        ul {
          li {
            a {
              &:hover,
              &:active {
                svg {
                  fill: $primary;
                }
              }
            }
          }
        }
      }
    }
  }
}

//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-center-wrapper {
    .it-header-center-content-wrapper {
      //-search
      .it-search-wrapper {
        a {
          &.rounded-icon {
            background: white;
            box-shadow: 0px 2px 5px 0px #b3b3b3;

            &:hover {
              background: $primary;

              svg {
                fill: $header-center-bg-color;
              }
            }

            svg {
              fill: $header-center-text-color;
            }
          }
        }
      }

      //right-zone
      .it-right-zone {
        .it-socials {
          ul {
            li {
              a {
                &:hover,
                &:active {
                  svg {
                    fill: $primary;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
