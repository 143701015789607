.container {
  .block {
    .searchSections {
      .searchSections-background {
        background-color: $tertiary;
      }

      .buttonsContainer {
        .btn.btn-primary {
          @extend .btn-tertiary;
        }
      }
    }
  }
}
