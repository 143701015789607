//site
$primary-h: 36 !default;
$primary-s: 95 !default;
$primary-b: 94 !default;

$primary-text: #000;

$secondary: #f0940d;
$secondary-text: #000;
$tertiary: #3f4142;

$primary-text-color: #000;
//$tertiary-text: $white;

$text-color: #333333;
$site-link-color: $text-color;
$link-hover-color: #455a64;

$header-bg-color: #eceff1;
$header-center-bg-color: $header-bg-color;
$header-center-text-color: $tertiary;

$gdpr-accept-all: $secondary;

$navigation-color: $tertiary;

$headings-color: $text-color;
//$semibold: 600;

$icon-size: 20px;

$light: #eceff1;

$argument-icon-color: $secondary;
$argument-icon-bg: rgba($secondary, 0.1);
