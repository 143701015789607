//mobile
.chip {
  &.chip-primary {
    color: #000;

    & > .chip-label {
      color: #000;
    }
  }
}
