.simple-card-default {
  .card.card-teaser {
    .categoryicon-top .icon {
      width: $icon-size;
      height: $icon-size;

      color: $text-color;
      fill: $text-color;
    }
  }
}

.simple-card-compact-template {
  .card-teaser-wrapper {
    &.card-teaser-block-3 {
      & > .card-teaser {
        > .icon {
          fill: $text-color;
        }
      }
    }
  }
}
