.block.listing .card-with-image-template,
.block.listing .in-evidence {
  .icon {
    width: $icon-size;
    height: $icon-size;
  }

  .listing-item {
    .card-title {
      a {
        &:hover,
        &:focus {
          color: $link-hover-color;
        }
      }
    }
  }
}
