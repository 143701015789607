a {
  color: $site-link-color;

  &:hover,
  &:active {
    color: $link-hover-color;
    text-decoration: underline;
  }

  &.read-more {
    color: $tertiary;
  }
}

.btn-primary,
.btn-secondary {
  .icon,
  .icon-white {
    color: $primary-text !important;
    fill: $primary-text !important;
  }
}

.callout-bg {
  background-color: $argument-icon-bg;
}
