.card {
  // .card-title {
  //   a {
  //     color: $body-color;
  //     &:hover,
  //     &:active {
  //       color: $link-hover-color;
  //     }
  //   }
  // }

  .categoryicon-top {
    .icon {
      fill: $text-color;
      width: $icon-size;
      height: $icon-size;
      min-width: $icon-size;
    }
  }

  // read more text
  a.read-more {
    color: $tertiary;

    svg {
      fill: $tertiary;
    }

    &:hover {
      color: $link-hover-color;

      svg {
        fill: $link-hover-color;
      }
    }
  }
}

// // flag
// .flag-icon {
//   background: $secondary;
// }
